<template>
    <div style="height: auto;background-color: #fff;border: 10px solid #edeff2;">
      <a-spin :spinning="spinning">
        <div class="search">
          咨询日期：
          <a-range-picker
            v-model="timeVal"
            @change="onChange_range_picker"
            style="width: 300px"
          />
        </div>
        <div>
          <div class="text">
            <h3>服务咨询列表</h3> 
            <a-button type="primary" @click="newBuild">+新增</a-button>
          </div>
          <div style="padding:15px">
            <a-table
              :columns="columns"
              style="min-height: 500px; background-color: #fff"
              :data-source="HeplTable"
              :rowKey="(record) => record.id"
              :pagination="false"
            >
              <template slot="index" slot-scope="text,row,index">
                {{index + ((pagination.pageNum - 1) * pagination.pageSize) + 1}}
              </template>
              <template slot="RegisterType" slot-scope="text,row,index,item">
                <a-tag :key="text"
                  :color="text === 0 ? 'purple' : 'blue'">
                  <span v-if="text === 1">
                    注册客户
                  </span>
                  <span v-else-if="text === 0">
                    未注册客户
                  </span>
                </a-tag>
              </template>
              <span slot="option" slot-scope="text, record">
                <a @click="editForm(record)">编辑</a>
                <a-popconfirm title="确认删除吗？" ok-text="是" cancel-text="否" @confirm="deleteRecord(record)">
                  <a style="margin-left:10px">删除</a>
                </a-popconfirm>
              </span>
              <span slot="content" slot-scope="text, item">
                <!-- 悬浮框 -->
                <a-tooltip placement="topLeft">
                  <template slot="title">
                    {{ text }}
                  </template>
                  <!-- 内容 -->
                  <a class="multi-ellipsis"> {{ text }}</a>
                </a-tooltip>
              </span>
            </a-table>
          <!-- table框的分页 -->
            <div
              style="
                margin-top: 28px;
                display: flex;
                align-items: center;
                justify-content: space-between;
              "
            >
              <span
                style="font-size: medium; color: #929292; font-size: 14px; bottom: 3%"
              >
                共{{ pagination.total }}条记录 第 {{ pagination.pageNum }} /
                {{ Math.ceil(pagination.total / pagination.pageSize) }} 页
              </span>
              <a-pagination
                style="float: right"
                show-quick-jumper
                show-size-changer
                :page-size.sync="pagination.pageSize"
                :total="pagination.total"
                v-model="pagination.current"
                @change="pageChange"
                @showSizeChange="pageChange"
              />
            </div>
          </div>
        </div>
        <!-- 新增/编辑弹窗 -->
        <a-modal v-model="modalShow"  title="新增/编辑" @cancel="handle2Cancel" @ok="submit">
          <a-form-model :model="addForm" :label-col="labelCol" :wrapper-col="wrapperCol" :rules="rules" ref="addForm">
            <a-form-model-item  label="注册类型" >
              <a-radio-group name="radioGroup" v-model="addForm.isRegister" >
                <a-radio :value="1">
                  已注册
                </a-radio>
                <a-radio :value="0">
                  未注册
                </a-radio>
              </a-radio-group>
            </a-form-model-item>
            <a-form-model-item label="姓名"  prop="memberName" >
                <a-select show-search :value="addForm.memberName" placeholder="请选择（输入搜索选择），未注册则输入" 
                  @blur="onBlur"
                  @change="onChange"
                  @search="onSearch" 
                  optionFilterProp="label"
                >
                <a-select-option
                  v-for="item in nameList"
                  :key="item.memberId"
                  :value="item.memberId"
                  :label="item.name"  
                  >
                  {{ item.name }}
                </a-select-option>
                </a-select>
                <!-- {{ nameList }} -->
            </a-form-model-item>
            <a-form-model-item  label="咨询时间" prop="consulationTime">
              <!-- <a-date-picker
                  v-model="addForm.consulationTime"
                  style="width: 100%"
                  format="YYYY-MM-DD"
              /> -->
              <a-date-picker
               style="width: 100%" 
               :disabled-date="disabledDate"
               :disabled-time="disabledDateTime"
               v-model="addForm.consulationTime" 
               show-time placeholder="请选择" 
               @change="ondateChange"  
               />
               <!-- <a-date-picker show-time placeholder="Select Time" @change="onChange" @ok="onOk" /> -->
            </a-form-model-item>
            <a-form-model-item  label="专属客服" prop="kfMemberId">
              <a-select
                placeholder="请选择"
                show-search
                v-model="addForm.kfMemberId"
                allowClear
              >
              <a-select-option
                v-for="item in kefuList"
                :key="item.id"
                :value="item.memberId"
                >
                {{ item.kefuName }}
              </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="渠道"  prop="channel" >
              <a-select v-model="addForm.channel" placeholder="请选择" @change="type5Change">
                <a-select-option value="商户后台">
                  商户后台
                </a-select-option>
                <a-select-option value="电话">
                  电话
                </a-select-option>
                <a-select-option value="医驰客服微信">
                  医驰客服微信
                </a-select-option>
                <a-select-option value="企业微信">
                  企业微信
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="咨询内容分类"  prop="consulationType" >
                <a-select v-model="addForm.consulationType" placeholder="请选择" @change="type5Change">
                  <a-select-option value="优化建议">
                    优化建议
                  </a-select-option>
                  <a-select-option value="使用方法">
                    使用方法
                  </a-select-option>
                  <a-select-option value="收费标准">
                    收费标准
                  </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item  label="咨询内容" prop="content" >
              <a-textarea v-model="addForm.content" auto-size placeholder="请至少输入五个字符" style="min-height: 100px;font-size: 16px"/>
            </a-form-model-item>
          </a-form-model>
        </a-modal>
      </a-spin>
    </div>
</template>

<script>
import {getSelectByCreated,getKefuList,getName,postInsCutomerConRecord,delCutomerConRecord,putUpdCutomerConRecord} from "@/service/customerManagement_api";
import moment from 'moment';
export default {
  data(){
    return{
      id:'',
      nameList:[],
      kefuList: [],
      StartTime:null,
      EndTime:null,
      rules:{
        // time: [{ required: true, message: '请选择', trigger: 'change' }],
        serviceContent: [{ required: true, message: '请输入', trigger: 'blur' }],
        consulationType: [{ required: true, message: '请选择', trigger: 'blur' }],
        memberName: [{ required: true, message: '请选择', trigger: 'blur' }],
        consulationTime: [{ required: true, message: '请选择', trigger: 'change' }],
        kfMemberId: [{ required: true, message: '请选择', trigger: 'blur' }],
        channel: [{ required: true, message: '请选择', trigger: 'blur' }],
        content: [{ required: true, message: '请选择', trigger: 'blur' }],
      },
      labelCol: { span: 6},
      wrapperCol: { span: 16 },
      addForm:{
        memberName:undefined,
        isRegister:"" ,
        kfMemberId:undefined
      },
      modalShow:false,
      spinning:false,
      timeVal: [],
      HeplTable:[],
      columns: [
          {
            title: "序号",
            dataIndex: "serviceName",
            key: "serviceName",
            align: "center",
            scopedSlots: { customRender: "index" },
            // width: "8%",
          },
          {
            title: "会员姓名",
            dataIndex: "memberName",
            key: "memberName",
            align: "center",
            // width: "15%",
          },
          {
            title: "类型",
            dataIndex: "isRegister",
            key: "isRegister",
            align: "center",
            scopedSlots: { customRender: "RegisterType" },
          },
          {
            title: "渠道",
            dataIndex: "channel",
            key: "channel",
            align: "center",
            // width: "28%",
          },
          {
            title: "内容",
            dataIndex: "content",
            key: "content",
            // align: "center",
            scopedSlots: { customRender: "content" },
          },
          {
            title: "咨询时间",
            dataIndex: "consulationTime",
            key: "consulationTime",
            align: "center",
            // scopedSlots: { customRender: "cancelChannel" },
          },
          {
            title: "操作",
            scopedSlots: { customRender: "option" },
          },
      ],
      pagination: {
        //存储大table的分页
        total: 0,
        current: 1,
        pageSize: 10,
        pageNum: 1,
      },
      name:"",
    }
  },
  created(){
    this.getTable()
  },
  methods:{
    // 限制日期不可选
    disabledDate(current) {
    	// Can not select days before today and today
    	// 只能选择当前日期的两个月范围内
    	// return current && (current > moment().endOf('day') || current <  moment().subtract(2, 'months'));
     
    	// 只能选择当前日期的前后一个月范围
    	// return current && (current >  moment().add(1, 'months') || current <  moment().subtract(1, 'months'));
    	// return current && current < moment().endOf('day') //当天之前的不可选，包括当天
    	// return current && current < moment().subtract(1, 'day') //当天之前的不可选，不包括当天
    	return current > moment() // 大于当前日期不能选
    	// return current < moment().subtract(1, 'days') // 小于当前日期不能选
    	// return current < moment().subtract(7, 'days') || current > moment().add(7, 'd') // 只能选前7后7
    },
    // 限制时间不可选
    disabledDateTime() {
      return {
    	// disabledHours: () => this.range(0, 24).splice(4, 20),
    	// disabledMinutes: () => this.range(30, 60),
    	// disabledSeconds: () => [55, 56],
      }
    },
    ondateChange(value, dateString) {
    //   console.log('Selected Time: ', value);
    //   console.log('Formatted Selected Time: ', dateString);
    this.addForm.consulationTime = dateString
    },
    onOk(value) {
    //   console.log('onOk: ', value);
    },
    // 获取列表
    async getTable() {
      let data = {};
      data = {
        beginDate: this.StartTime,
        endDate: this.EndTime,
        page:this.pagination.pageNum,
        pageSize:this.pagination.pageSize
      };
      const response = await getSelectByCreated(data);
      if (response.code === 200) {
        this.HeplTable = response.data;
        this.pagination.total = response.count;
      } else {
        this.$message.warning(response.message);
      }
    },
    // 获取客服列表
    async getKefu() {
      this.kefuList = []
      const res = await getKefuList("", 1, 999);
      if (res.code == 0) {
        this.kefuList = res.data;
      }
    },
    async deleteRecord(record){
      const response = await delCutomerConRecord(record.id);
      if (response.code === 200) {
        this.getTable()
        this.$message.success("操作成功");
      } else {
        this.$message.warning(response.message);
      }
    },
    //change事件 
    onChange(value,e){
      this.addForm.memberName = value;
      if(e){
        this.addForm.memberId = value
        this.addForm.memberName = e.componentOptions.children[0].elm.data.trim()
      }
    //   const label  = e.componentOptions.children[0].elm.data.trim()
    //  this
    //   console.log("值",value,e.componentOptions.children[0].elm.data.trim())
    },
    //search事件
    async onSearch(value){
      this.onChange(value)
      //搜索方法
      this.name = value
      await this.getName()
    },
    //blur事件
    onBlur(value){
        // console.log(value)
      this.addForm.memberName = value
    },
    type5Change(){

    },
    // 日期
    onChange_range_picker(date, dateString) {
      this.StartTime = dateString[0];
      this.EndTime = dateString[1];
      this.pagination.pageSize = 10;
      this.pagination.pageNum = 1;
      this.getTable();
    },
    // 分页
    pageChange(page_no, page_size) {
      this.pagination.pageNum = page_no;
      this.pagination.pageSize = page_size;
      this.getTable();
    },
    newBuild(){
      this.modalShow = true
      this.getKefu()
      this.getName()
      const userData = JSON.parse(window.localStorage.getItem("yichi_userInfo"));
      this.addForm.kfMemberId = userData.id
    },
    async getName(){
      const res = await getName(this.name);
      if (res.code === 200) {
        this.nameList = [];
        for (let i=0;i<res.data.length;i++){
          this.nameList.push({
            memberId: res.data[i].memberId,
            name: res.data[i].name,
          })
        }
        // this.nameList = [
        //   {
        //     memberId: "717a55e3-405b-4adf-ac74-5af588edf6c5",
        //     name: "李凌"
        //   }
        // ]
        // console.log("怎么会没有数据",this.nameList)  //暂时没数据
      }
    },
    handle2Cancel(){
      this.modalShow = false
      this.$refs.addForm.resetFields()
      this.addForm = {memberName:undefined,isRegister:''}   
      this.id = '' 
    //   this.$refs.ServiceForm.resetFields()
    //   this.ServiceForm = {time:[],serviceStatus:undefined,serviceContent:''}
    },
    submit(){
      this.$refs.addForm.validate(async valid => { // 表单校验
        if(valid) {
          if(this.id !==''){  //修改 
            const data = {
              "id":this.id,
              "isRegister": this.addForm.isRegister, //注册类型0未注册 1已注册
              "memberId": this.addForm.isRegister === 0 ? null:this.addForm.memberId, //用户id 未注册时为null
              "memberName": this.addForm.memberName, //姓名
              "consulationTime": this.addForm.consulationTime, //咨询时间
              "kfMemberId": this.addForm.kfMemberId, //专属客服id
              "channel": this.addForm.channel, //渠道
              "consulationType": this.addForm.consulationType, //咨询内容分类
              "content": this.addForm.content //咨询内容
            }
            const response = await putUpdCutomerConRecord(data);
            if (response.code === 200) {
              this.$message.success("修改成功");
              this.modalShow = false
              this.$refs.addForm.resetFields()
              this.addForm = {memberName:undefined,isRegister:''}    
              this.getTable()
              this.id = ''
            } else {
              this.$message.warning(response.message);
            }
          }else{
            const data = {
              "isRegister": this.addForm.isRegister, //注册类型0未注册 1已注册
              "memberId": this.addForm.isRegister === 0 ? null:this.addForm.memberId, //用户id 未注册时为null
              "memberName": this.addForm.memberName, //姓名
              "consulationTime": this.addForm.consulationTime, //咨询时间
              "kfMemberId": this.addForm.kfMemberId, //专属客服id
              "channel": this.addForm.channel, //渠道
              "consulationType": this.addForm.consulationType, //咨询内容分类
              "content": this.addForm.content //咨询内容
            }
            // console.log("时间这么那样了",data)
            const response = await postInsCutomerConRecord(data);
            if (response.code === 200) {
              this.$message.success("新增成功");
              this.modalShow = false
              this.$refs.addForm.resetFields()
              this.addForm = {memberName:undefined}    
              this.getTable()
              this.id = ''
            } else {
              this.$message.warning(response.message);
            }
          }
         
        }})
    },
    editForm(record){
      this.modalShow = true
      this.getKefu()
      this.getName()
      this.addForm = JSON.parse(JSON.stringify(record))  //content和备注
      this.id = record.id
    }
  }
}
</script>

<style>
  .search {
    background-color: #fff;
    width: 100%;
    height: 64px;
    padding-left: 15px;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
    /* margin-bottom: 10px; */
    border-bottom:10px solid rgb(237, 239, 242)
  }
  .text{
    height: 45px;
    border-bottom:1px solid rgb(237, 239, 242);
    display: flex;
    align-items:center;
    justify-content: space-between;
    padding-left:15px;
    padding-right:20px;
    h3{
      font-weight:bold;
      margin-bottom:0;
    }
  }
  ::v-deep .ant-table-thead > tr > th {
    font-weight: bold;
  }
  .multi-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    cursor: pointer;
}
</style>