//公共的api接口
import {post,get, postLogin, put,putById, putSetPassWord,del,getById,postById,delBiId} from "@/service/base";

export function  getServiceConcult(data){
    return get('/api/kefu/serviceConcult',data)
}
export function  postServiceConcult(data){
    return put('/api/kefu/serviceConcult',data)
}
// 取消订单
export function  putOrder(data){
    return putById('/api/admin/cancellationByOrderNumber',data)
}
// 单条服务记录
export function  getServiceRecord(data){
    return get('/api/admin/kefu/serviceRecord',data)
}
// 新增服务记录
export function  postServiceRecord(data){
    return post('/api/admin/kefu/serviceRecord',data)
}
// 修改表单提交
export function  putServiceRecord(data){
    return put('/api/admin/kefu/serviceRecord',data)
}
// 删除服务记录  /api/admin/kefu/serviceRecord/{id}
export function delRecords(id) {
    return del(`/api/admin/kefu/serviceRecord/${id}`);
}
// 获取评价
export function  getServiceComment(data){
    return get('/api/admin/serviceConsultation/comment',data)
}
// 我的客户
export function  getHomePageVisitedRecords(data){
    return get('/api/admin/kefu/customs',data)
}

// 咨询管理  
export function  getSelectByCreated(data){
    return getById('/api/services/selectByCreated',data)
}
// 获取客服列表
export function getKefuList(name, pageNo, pageSize) {
    return get(`/master/api/admin/kefu?name=${name}&pageNo=${pageNo}&pageSize=${pageSize}`)
}
// 姓名列表
export function getName(name) {
  return get(`/api/services/selectMember?name=${name}`)
}
// 新增咨询记录
export function  postInsCutomerConRecord(data){
    return postById('/api/services/insCutomerConRecord',data)
}
// 删除咨询记录 
export function delCutomerConRecord(id) {
  return putById(`/api/services/delCutomerConRecord/${id}`);
}
// 修改
export function  putUpdCutomerConRecord(data){
    return putById('/api/services/updCutomerConRecord',data)
}